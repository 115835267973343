<template>
  <el-dialog
    :title="title"
    :visible="addProjectDialog"
    @close="resetForm"
    width="72%"
    style="padding: 0 0 100px 0px"
    :show-overflow-tooltip="true"
    :close-on-click-modal="false"
    :destroy-on-close="false"
    lock-scroll
  >
    <el-form
      :model="params"
      ref="addProject"
      :rules="rules"
      class="addDailog"
      onsubmit="return false;"
      label-width="140px"
      label-position="right"
    >
      <el-form-item label="项目名" prop="name">
        <el-input
          type="text"
          maxlength="32"
          show-word-limit
          v-model="params.name"
          width="100"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="公司名称"
        prop="principalName"
        style="position: relative"
      >
        <div style="display: flex">
          <el-input
            v-model="params.principalName"
            placeholder="请输入内容"
            @blur="enter"
            @keyup.enter.native="enter"
          ></el-input>
          <el-button @click="enter" style="margin-left: 5px" type="primary"
            >工商查询</el-button
          >
        </div>

        <ul class="mkName" :style="{ display: activeClass }">
          <li
            v-for="item in restaurantsres"
            @click="compamyMh(item)"
            :key="item.id"
          >
            {{ item.value }}
          </li>
        </ul>
      </el-form-item>

      <div
        class="roleCodeList flexJfAc"
        v-for="(item, index) in params.industryList"
        :key="index"
      >
        <el-form-item prop="partnerName">
          <span slot="label"> 行业{{ index + 1 }} </span>

          <el-select
            v-model="item.industry"
            @visible-change="
              selectListDict(
                $event,
                'investmentIndustryOptions',
                'Investment_Industry'
              )
            "
            filterable
            placeholder="请选择"
            clearable
            @change="investmentIndustryOne($event, index)"
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in investmentIndustryOptions"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="investRatio">
          <span slot="label"> 细分赛道{{ index + 1 }} </span>
          <el-select
            v-model="item.subIndustry"
            style="width: 75% !important; float: left"
            @visible-change="getSubIndustryOptions($event, index)"
            filterable
            placeholder="请选择"
            clearable
            @change="investmentSubIndustryOne($event, index)"
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in investmentsubIndustryOptions"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <div class="addContributionRatio">
            <i
              @click="removeContributionRatio(index)"
              class="el-icon-remove-outline"
            ></i>
            <i
              @click="addContributionRatio"
              class="el-icon-circle-plus-outline"
            ></i>
          </div>
        </el-form-item>
      </div>
      <el-form-item label="模式" prop="mode">
        <el-select
          v-model="params.mode"
          @visible-change="
            selectListDict($event, 'projectModeOptions', 'project_mode')
          "
          filterable
          placeholder="请选择"
          clearable
          multiple
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in projectModeOptions"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="一句话描述" prop="brief">
        <el-input type="text" v-model="params.brief" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="投资机构" prop="orgIdList">
        <el-select
          v-model="params.orgIdList"
          @visible-change="fastSearchOrg($event)"
          filterable
          placeholder="请选择"
          clearable
          multiple
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in investmenInstitutionsOptions"
            :key="item.id"
            :label="item.organizationName"
            :value="item.id"
          >
            <span>{{ item.organizationName }}</span>
            <span class="workflowVersion">{{
              item.category == 1
                ? "财务投资"
                : item.category == 2
                ? "产业投资"
                : item.category == 3
                ? "金融机构"
                : ""
            }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="网店" prop="onlineShopIdList">
        <el-select
          v-model="params.onlineShopIdList"
          class="childWidth"
          filterable
          placeholder="请选择网店"
          @visible-change="getShopSelectList($event)"
          clearable
          multiple
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in shopSelectList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
            <span>{{ item.name }}</span>
            <span class="workflowVersion">
              {{ item.type == 1 ? "淘宝" : item.type == 2 ? "京东" : "" }}</span
            >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="官网" prop="principalWebsite">
        <el-input
          type="text"
          show-word-limit
          v-model="params.principalWebsite"
          width="100"
        ></el-input>
      </el-form-item> -->
      <el-form-item label="所在城市" prop="selectCityList">
        <div>
          <el-select
            style="width: 50% !important"
            placeholder="请选择"
            v-model="params.principalLocationOther"
            @change="selectProvince($event)"
            clearable
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in provinceList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
          <el-select
            style="width: 50% !important"
            placeholder="请选择"
            v-model="params.selectCityList"
            @change="selectCityOne($event)"
            clearable
            no-data-text="加载中···"
          >
            <el-option
              v-for="city in cityList"
              :label="city.name"
              :key="city.code"
              :value="city.code"
            >
              {{ city.name }}</el-option
            >
          </el-select>
        </div>
      </el-form-item>
      <el-form-item prop="revenueScale">
        <span slot="label">营收规模</span>
        <el-select
          v-model="params.revenueScale"
          @visible-change="
            selectListDict($event, 'revenueScaleOptions', 'revenue_scale')
          "
          filterable
          placeholder="请选择"
          clearable
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in revenueScaleOptions"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="品牌" class="roleCodeList" prop="brand">
        <el-tag
          :key="tag"
          v-for="tag in params.brand"
          closable
          :disable-transitions="false"
          @close="handleClose(tag)"
        >
          {{ tag }}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="inputVisible"
          v-model="inputValue"
          ref="saveTagInput"
          size="small"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
        >
        </el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showInput"
          >+ 新增品牌</el-button
        >
      </el-form-item>
      <el-form-item
        label="搜索关键字"
        class="roleCodeList"
        prop="searchKeywords"
      >
        <el-tag
          :key="tag"
          v-for="tag in params.searchKeywords"
          closable
          :disable-transitions="false"
          @close="handleCloseSearchKeywords(tag)"
        >
          {{ tag }}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="inputSearchKeywordsVisible"
          v-model="inputSearchKeywordsValue"
          ref="saveTagInput"
          size="small"
          @keyup.enter.native="handleInputSearchKeywordsConfirm"
          @blur="handleInputSearchKeywordsConfirm"
        >
        </el-input>
        <el-button
          v-else
          class="button-new-tag"
          size="small"
          @click="showInputSearchKeywords"
          >+ 新增搜索关键字</el-button
        >
      </el-form-item>

      <!-- <el-form-item class="roleCodeList" label="行业" prop="industryList">
        <el-select v-model="params.industryList" multiple placeholder="请选择">
          <el-option
            v-for="item in industryList"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </el-form-item> -->
      <!-- <el-form-item label="公司评级" prop="rating">
        <el-input
          type="text"
          show-word-limit
          v-model="params.rating"
          width="100"
        ></el-input>
      </el-form-item>
      
      <el-form-item label="公司状态" prop="principalStatus">
        <el-select
          v-model="params.principalStatus"
          @visible-change="
            selectListDict(
              $event,
              'organizationStatusOption',
              'organization_status'
            )
          "
          filterable
          placeholder="请选择"
          clearable
        >
          <el-option
            v-for="item in organizationStatusOption"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item> -->

      <el-form-item class="roleCodeList" label="项目简介" prop="intro">
        <el-input
          type="textarea"
          v-model="params.intro"
          :autosize="{ minRows: 2 }"
          maxlength="600"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item class="roleCodeList" label="项目亮点" prop="shiningPoint">
        <el-input
          type="textarea"
          v-model="params.shiningPoint"
          :autosize="{ minRows: 2 }"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item class="roleCodeList" label="融资情况" prop="financeInfo">
        <el-input
          type="textarea"
          v-model="params.financeInfo"
          :autosize="{ minRows: 2 }"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item class="roleCodeList" label="团队履历" prop="teamResume">
        <el-input
          type="textarea"
          v-model="params.teamResume"
          :autosize="{ minRows: 2 }"
          maxlength="600"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-divider content-position="left">联系方式</el-divider>
      <el-form-item label="项目BD" prop="initialOwnerId">
        <el-select
          v-model="params.initialOwnerId"
          @visible-change="projectTeam2($event)"
          @change="chooseInitialOwner($event)"
          filterable
          placeholder="请选择项目BD"
          clearable
          class="childWidth"
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in internalUseroptionsList"
            :key="item.uid"
            :label="item.realName"
            :value="item.uid"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="联系人" prop="contactName">
        <el-input
          style="width: calc(100% - 100px) !important"
          type="text"
          show-word-limit
          v-model="params.contactName"
          width="60"
        ></el-input>
        <el-select
          style="width: 100px !important"
          :reserve-keyword="false"
          v-model="params.contactGender"
          placeholder="请选择"
          clearable
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in contactGenderList"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="联系电话" prop="contactPhone">
        <el-input
          type="text"
          show-word-limit
          v-model="params.contactPhone"
          width="60"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系人职位" prop="contactPosition">
        <el-input
          type="text"
          show-word-limit
          v-model="params.contactPosition"
          width="60"
        ></el-input>
      </el-form-item>
      <el-form-item label="微信" prop="contactWechat">
        <el-input
          type="text"
          show-word-limit
          v-model="params.contactWechat"
          width="60"
        ></el-input>
      </el-form-item>
      <el-form-item label="E-Mail" prop="contactMail">
        <el-input
          type="text"
          show-word-limit
          v-model="params.contactMail"
          width="60"
        ></el-input>
      </el-form-item>

      <el-divider content-position="left">其他</el-divider>
      <el-form-item label="投资主体" prop="investorPrincipalId">
        <el-select
          v-model="params.investorPrincipalId"
          @visible-change="getCompanySelectList($event)"
          @change="chooseCompany($event)"
          filterable
          placeholder="请选择基金公司"
          clearable
          class="childWidth"
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in fundCompangOptios"
            :key="item.id"
            :label="item.fullName"
            :value="item.id"
          >
            <span style="float: left">{{ item.name }}</span>
            <span style="float: left; color: #8492a6; font-size: 13px"
              >（{{ item.fullName }}）</span
            >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="成立时间" prop="principalCreateTime">
        <el-date-picker
          v-model="params.principalCreateTime"
          type="date"
          placeholder="选择日期"
          align="right"
          :picker-options="pickerOptions"
          class="childWidth"
          value-format="timestamp"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item class="roleCodeList" label="备注" prop="remark">
        <el-input
          type="textarea"
          v-model="params.remark"
          :autosize="{ minRows: 2 }"
          maxlength="600"
          show-word-limit
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="项目批准（立项）时间" prop="approvalTime">
        <el-date-picker
          v-model="params.approvalTime"
          type="date"
          placeholder="选择日期"
          align="right"
          :picker-options="pickerOptions"
          class="childWidth"
          value-format="timestamp"
        >
        </el-date-picker>
      </el-form-item> -->
      <!-- <el-form-item label="合作开始时间" prop="cooperateBeginTime">
        <el-date-picker
          v-model="params.cooperateBeginTime"
          type="date"
          placeholder="选择日期"
          align="right"
          :picker-options="pickerOptions"
          class="childWidth"
          value-format="timestamp"
        >
        </el-date-picker>
      </el-form-item> -->
      <!-- <el-form-item label="计划完成时间" prop="planFinishTime">
        <el-date-picker
          v-model="params.planFinishTime"
          type="date"
          placeholder="选择日期"
          align="right"
          :picker-options="pickerOptions"
          class="childWidth"
          value-format="timestamp"
        >
        </el-date-picker>
      </el-form-item> -->
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="resetForm">取 消</el-button>

      <el-button type="primary" @click="addProject">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { ROUNDS, INDUSTRY_LIST } from "@/common/constants";
import { PROVINCE_LIST } from "@/common/province_code";
import { CITY_MAP } from "@/common/city_code";
import {
  projectTeam,
  addProject,
  editProject,
  findProjectDetail,
} from "@/api/projectManage";
import { searchCompany } from "@/api/fundCompany";

import { roleSelectList } from "@/api/jurisdiction";
import { getCompanySelectList } from "@/api/fundCompany";
import { fastSearchOrg } from "@/api/institutionManage";
import { getShopSelectList } from "@/api/externalData";

export default {
  name: "addProject",
  props: ["type", "addProjectDialog", "projectInfo", "category"],
  data() {
    var isMobileNumber = (rule, value, callback) => {
      if (!value) {
        return new Error("请输入电话号码");
      } else {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
        const isPhone = reg.test(value);
        value = Number(value); //转换为数字
        if (typeof value === "number" && !isNaN(value)) {
          //判断是否为数字
          value = value.toString(); //转换成字符串
          if (value.length < 0 || value.length > 12 || !isPhone) {
            //判断是否为11位手机号
            callback(new Error("手机号码格式如:138xxxx8754"));
          } else {
            callback();
          }
        } else {
          callback(new Error("请输入电话号码"));
        }
      }
    };
    const validateMoney = (rule, value, callback) => {
      if (!value) {
        callback(new Error("不能为空"));
      } else if (value.indexOf(".") != -1 && value.split(".").length > 2) {
        callback(new Error("请输入正确格式的金额")); //防止输入多个小数点
      } else if (value.indexOf(".") != -1 && value.split(".")[1].length > 2) {
        callback(new Error("请输入正确的小数位数")); //小数点后两位
      } else {
        callback();
      }
    };
    return {
      title: "新增",
      loading: false,
      msPd: null,
      provinceList: PROVINCE_LIST,
      provinceCityMap: CITY_MAP,
      cityList: [],
      organizationStatusOption: [],
      params: {
        projectWorkflowId: null,
        companyName: null,
        rounds: null,
        companyOriginId: null,
        projectName: null,
        rating: null,
        note: null,
        evaluationTemplateId: null,
        type: "1",
        currencyType: "CNY",
        projectApprovalTime: new Date(),
        contactGender: null,
        contactMail: null,
        contactName: null,
        contactPhone: null,
        contactPosition: null,
        contactWechat: null,
        demandAmount: undefined,
        faEndTime: null,
        faExclusive: "",
        faRatio: undefined,
        faSigningTime: null,
        intro: null,
        postInvestmentValuation: undefined,
        preInvestmentValuation: undefined,
        transferRatio: undefined,
        industryList: [
          {
            industry: null,
            subIndustry: null,
            industryCode: null,
            industry: null,
          },
        ],
        mode: null,
        brand: [],
        searchKeywords: [],
        shiningPoint: null,
        financeInfo: null,
        principalCreateTime: null,
        principalLocation: {},
        principalLocationOther: null,
        selectCityList: null,
        organizationList: [],
        revenueScale: null,
      },
      options: [],
      searchName: null,
      tz: [],
      name: [],
      arr: [],
      activeClass: "none",
      restaurantsres: [],
      restaurants: [],
      rules: {
        investorPrincipalId: [
          { required: false, message: "请选择基金公司", trigger: "change" },
        ],
        initialOwnerId: [
          { required: true, message: "请选择项目BD", trigger: "change" },
        ],
        // projectName: [{ validator: checkProjectName, trigger: "blur" }],
        name: [{ required: true, message: "请输入项目名", trigger: "blur" }],
        principalName: [
          { required: true, message: "请输入公司名", trigger: "blur" },
        ],
        rounds: [
          { required: false, message: "请选择融资阶段", trigger: "change" },
        ],
        projectWorkflowId: [
          { required: false, message: "请选择流程", trigger: "change" },
        ],

        evaluationTemplateId: [
          { required: false, message: "请选择模版", trigger: "change" },
        ],
        roleCodeList: [
          { required: false, message: "请选择项目组成员", trigger: "change" },
        ],
        currencyType: [
          { required: false, message: "请选择币种", trigger: "change" },
        ],
        demandAmount: [
          { required: false, message: "不能为空" },
          { type: "number", message: "必须为数字值" },
        ],
        transferRatio: [
          { required: false, message: "不能为空" },
          { type: "number", message: "必须为数字值" },
        ],
        preInvestmentValuation: [
          { required: false, message: "不能为空" },
          { type: "number", message: "必须为数字值" },
        ],
        // contactName: [
        //   { required: false, message: "请输入联系人姓名", trigger: "blur" },
        // ],
        // contactPhone: [
        //   { required: false, message: "请输入手机号码", trigger: "blur" },
        //   { validator: isMobileNumber, trigger: "blur" },
        // ],
        faSigningTime: [
          { required: false, message: "请选择日期", trigger: "change" },
        ],
        industryList: [
          { required: true, message: "请选择行业", trigger: "change" },
        ],
        intro: [{ required: true, message: "请输入项目简介", trigger: "blur" }],
        // contactPosition: [
        //   { required: false, message: "请输入联系人职位", trigger: "blur" },
        // ],
        brief: [
          { required: true, message: "请输入一句话描述", trigger: "blur" },
        ],
        selectCityList: [
          { required: true, message: "请选择城市", trigger: "change" },
        ],
      },
      typeList: [
        {
          lable: 1,
          name: "直投",
        },
        {
          lable: 2,
          name: "FA",
        },
      ],
      faExclusiveList: [
        {
          lable: 1,
          name: "是",
        },
        {
          lable: 0,
          name: "否",
        },
      ],
      currencyTypeList: [
        {
          code: "CNY",
          name: "人民币",
        },
        {
          code: "USD",
          name: "美金",
        },
      ],
      contactGenderList: [
        {
          code: 0,
          name: "女士",
        },
        {
          code: 1,
          name: "先生",
        },
        {
          code: 2,
          name: "未知",
        },
      ],
      tableData: [],
      pageInfo: {
        total: 0,
        pageNum: 1,
        pageSize: 5,
        pageCount: 1,
      },
      roleSelectListOptions: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      internalUseroptionsList: [],
      fundCompangOptios: [],
      industryList: INDUSTRY_LIST,
      projectModeOptions: null,
      inputVisible: false,
      inputSearchKeywordsVisible: false,
      inputValue: "",
      inputSearchKeywordsValue: "",
      investmentIndustryOptions: [],
      investmentsubIndustryOptions: [],
      investmenInstitutionsOptions: [],
      shopSelectList: [],
      revenueScaleOptions: [],
    };
  },
  computed: {},
  watch: {
    msPd(val, i) {
      console.log(val);
      var that = this;
      if (val) {
        this.openDialog(val);
        this.projectTeam2(true);
        this.getCompanySelectList(true);
        this.roleSelectList(true);
      }
    },
    deep: true,
  },
  created() {},
  mounted() {
    ROUNDS.forEach((val) => {
      this.tz.push({
        value: val,
        label: val,
      });
    });

    if (this.type == "add") {
      this.title = "添加项目";
    } else {
      this.title = "编辑项目";
      this.findProjectDetail(this.projectInfo);
      this.selectListDict(
        true,
        "organizationStatusOption",
        "organization_status"
      );
      //  this.selectListDict(
      //   true,
      //   "projectModeOptions",
      //   "project_mode"
      // );
    }
  },
  methods: {
    getShopSelectList(cb) {
      if (cb) {
        getShopSelectList({}).then((response) => {
          if (response.success) {
            let shopSelectList = response.data;
            shopSelectList.forEach(function (item, i) {
              item.id = String(item.id);
            });
            this.shopSelectList = shopSelectList;
          } else {
            this.shopSelectList = null;
          }
        });
      }
    },
    fastSearchOrg(cb) {
      if (cb) {
        let info = {
          organizationName: null,
          pageSize: 999,
          pageNum: 1,
        };
        fastSearchOrg(info).then((response) => {
          if (response.success) {
            this.investmenInstitutionsOptions = response.data;
          }
        });
      }
    },
    investmentIndustryOne(val, index) {
      var that = this;
      console.log(val);
      const i_index = index;
      console.log(i_index);

      // that.params.industryList[i_index].industry = null;
      // that.params.industryList[i_index].industry = null;
      // that.params.industryList[i_index].industryCode = null;
      that.params.industryList[i_index].subIndustry = null;

      if (val != "") {
        that.investmentIndustryOptions.forEach(function (item, i) {
          if (val == item.code) {
            that.params.industryList[i_index].industry = item.name;
            that.params.industryList[i_index].industryCode = item.code;
          }
        });
        if (that.type == "add") {
          if (that.params.industryList.length > 1) {
            var list = JSON.parse(JSON.stringify(that.params.industryList));
            list.splice(i_index, 1);
            console.log(list);
            list.forEach(function (item, index) {
              if (val == item.industryCode) {
                console.log("erreo");
                console.log(val);
                console.log(item.industryCode);
                that.params.industryList[i_index].industry = null;
                that.params.industryList[i_index].industryCode = null;
                that.$message({
                  type: "warning",
                  message: "行业不可重复选择!",
                });
              }
            });
          }
        } else {
          if (that.params.industryList.length > 1) {
            var list = JSON.parse(JSON.stringify(that.params.industryList));
            list.splice(i_index, 1);
            console.log(list);
            list.forEach(function (item, index) {
              if (that.params.industryList[i_index].industry == item.industry) {
                console.log("erreo");
                console.log(val);
                console.log(item.industry);
                that.params.industryList[i_index].industry = null;
                that.params.industryList[i_index].industryCode = null;
                that.$message({
                  type: "warning",
                  message: "行业不可重复选择!",
                });
              }
            });
          }
        }
      } else {
        console.log("清空");
        that.params.industryList[i_index].industry = null;
        that.params.industryList[i_index].industry = null;
        that.params.industryList[i_index].industryCode = null;
        that.params.industryList[i_index].subIndustry = null;
      }
    },
    getSubIndustryOptions(cb, index) {
      var that = this;
      this.investmentsubIndustryOptions = [];
      if (cb) {
        if (that.type == "add") {
          if (that.params.industryList[index].industryCode) {
            that.selectListDict(
              true,
              "investmentsubIndustryOptions",
              that.params.industryList[index].industryCode
            );
          }
        } else {
          console.log("121");
          that.investmentIndustryOptions.forEach(function (item, i) {
            if (that.params.industryList[index].industry == item.name) {
              that.params.industryList[index].industry = item.name;
              that.params.industryList[index].industryCode = item.code;
              that.selectListDict(
                true,
                "investmentsubIndustryOptions",
                that.params.industryList[index].industryCode
              );
            }
          });
        }
      }
    },
    investmentSubIndustryOne(val, index) {
      var that = this;
      // console.log(val);
      let i_index = index;
      if (val) {
      } else {
        that.params.industryList[i_index].subIndustry = null;
      }
    },
    addContributionRatio() {
      let one = {
        industry: null,
        subIndustry: null,
        industryCode: null,
        industry: null,
      };
      this.params.industryList.push(one);
    },
    removeContributionRatio(index) {
      if (index > 0) {
        this.params.industryList.splice(index, 1);
      }
    },
    //省份选中发生变化
    selectProvince(code) {
      console.log(code);
      let provinceCode;
      let provinceName;
      this.provinceList.forEach(function (item, index) {
        if (code == item.code) {
          provinceCode = item.code;
          provinceName = item.name;
        }
      });
      this.cityList = this.provinceCityMap[provinceCode];
      let cityListTemp = [];
      this.cityList.forEach((v) => {
        cityListTemp.push({
          provinceCode: provinceCode,
          province: provinceName,
          code: v.code,
          name: v.name,
        });
      });
      this.cityList = cityListTemp;
      this.params.selectCityList = null;
    },
    selectCityOne(code) {
      console.log(code);
      var that = this;

      that.cityList.forEach(function (item, index) {
        if (code == item.code) {
          that.params.principalLocation.provinceCode = item.provinceCode;
          that.params.principalLocation.province = item.province;
          that.params.principalLocation.cityCode = item.code;
          that.params.principalLocation.city = item.name;
          that.params.selectCityList = item.code;

          that.$forceUpdate();
        }
      });
    },

    handleClose(tag) {
      this.params.brand.splice(this.params.brand.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.params.brand.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },

    handleCloseSearchKeywords(tag) {
      this.params.searchKeywords.splice(
        this.params.searchKeywords.indexOf(tag),
        1
      );
    },
    showInputSearchKeywords() {
      this.inputSearchKeywordsVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputSearchKeywordsConfirm() {
      let inputSearchKeywordsValue = this.inputSearchKeywordsValue;
      if (inputSearchKeywordsValue) {
        this.params.searchKeywords.push(inputSearchKeywordsValue);
      }
      this.inputSearchKeywordsVisible = false;
      this.inputSearchKeywordsValue = "";
    },
    async selectListDict(e, list, type) {
      if (e) {
        this[`${list}`] = await this.$store
          .dispatch("selectListDict", type)
          .then((res) => {
            return res.data;
          });
      }
    },
    chooseInitialOwner(id) {
      var that = this;
      console.log(id);
      that.internalUseroptionsList.forEach(function (item, index) {
        if (id == item.uid) {
          that.params.initialOwnerName = item.username;
        }
      });
    },
    chooseCompany(id) {
      var that = this;
      console.log(id);
      that.fundCompangOptios.forEach(function (item, index) {
        if (id == item.id) {
          that.params.investorPrincipalName = item.fullName;
        }
      });
    },
    getCompanySelectList(cb) {
      if (cb) {
        getCompanySelectList({ type: 1 }).then((response) => {
          if (response.success) {
            this.fundCompangOptios = response.data;
          }
        });
      }
    },
    projectTeam2(cb) {
      if (cb) {
        projectTeam({}).then((response) => {
          if (response.success) {
            this.internalUseroptionsList = response.data;
          }
        });
      }
    },
    addProject() {
      console.log("addProject");
      //添加|修改 请求数据
      var that = this;
      var form = {
        ...this.params,
        category: this.category,
      };
      that.$refs["addProject"].validate((valid) => {
        if (valid) {
          console.log("-------------valid" + valid);
          console.log(form);
          console.log("form");
          if (that.params.industryList[0].industry) {
            that.params.industryList.forEach(function (item, i) {
              item.industry = item.industry || item.industry;
              item.subIndustry = item.subIndustry;
            });
          } else {
            form.industryList = null;
          }

          if (Array.isArray(form.brand)) {
            form.brand = form.brand.join(",");
          }
          if (Array.isArray(form.searchKeywords)) {
            form.searchKeywords = form.searchKeywords.join(",");
          }
          if (Array.isArray(form.mode)) {
            form.mode = form.mode.join(",");
          }

          if (this.type == "edit") {
            editProject(form).then((response) => {
              if (response.success) {
                this.$message({ type: "success", message: "编辑成功!" });
                this.$emit("listenAddProjectClose", {
                  type: "sure",
                  isClose: true,
                });
              }
            });
          } else if (this.type == "add") {
            addProject(form).then((response) => {
              if (response.success) {
                this.$message({ type: "success", message: "添加成功!" });
                this.$emit("listenAddProjectClose", {
                  type: "sure",
                  isClose: true,
                });
              }
            });
          }
        } else {
          console.log("-------------error");
        }
      });
    },
    resetForm() {
      this.$emit("listenAddProjectClose", { type: "cancel", isClose: true });
    },
    findProjectDetail(val) {
      findProjectDetail(val).then((res) => {
        if (res.success) {
          let msPd = res.data;
          if (msPd.investorPrincipalId) {
            msPd.investorPrincipalId = Number(res.data.investorPrincipalId);
          }
          if (msPd.principalLocation) {
            msPd.principalLocationOther = msPd.principalLocation.provinceCode;
            this.cityList =
              this.provinceCityMap[msPd.principalLocation.provinceCode];

            msPd.selectCityList = msPd.principalLocation.cityCode;
          }
          if (msPd.principalLocation == null) {
            msPd.principalLocation = {};
            msPd.principalLocationOther = null;
            msPd.selectCityList = null;
          }

          if (msPd.principalStatus) {
            msPd.principalStatus = String(res.data.principalStatus);
          }
          if (!Array.isArray(msPd.brand) && msPd.brand) {
            msPd.brand = msPd.brand.split(",");
          } else {
            msPd.brand = [];
          }
          if (!Array.isArray(msPd.searchKeywords) && msPd.searchKeywords) {
            msPd.searchKeywords = msPd.searchKeywords.split(",");
          } else {
            msPd.searchKeywords = [];
          }

          if (!Array.isArray(msPd.mode) && msPd.mode) {
            msPd.mode = msPd.mode.split(",");
          } else {
            msPd.mode = [];
          }
          this.selectListDict(
            true,
            "investmentIndustryOptions",
            "Investment_Industry"
          );
          this.selectListDict(true, "revenueScaleOptions", "revenue_scale");
          this.getShopSelectList(true);
          this.fastSearchOrg(true);
          if (msPd.industryList) {
            this.investmentIndustryOptions.forEach(function (item, i) {
              msPd.industryList.forEach(function (item2, i2) {
                if (item2.industry == item.name) {
                  item2.industryCode = item.group;
                }
              });
            });
          } else {
            msPd.industryList = [
              {
                industry: null,
                subIndustry: null,
                industryCode: null,
                industry: null,
              },
            ];
          }
          this.selectListDict(true, "projectModeOptions", "project_mode");
          this.msPd = msPd;

          // this.msPd.uidList = res.data.uidList.map(item => item.uid);
        }
      });
    },

    roleSelectList(cb) {
      if (cb && this.roleSelectListOptions.length == 0) {
        roleSelectList().then((response) => {
          this.roleSelectListOptions = response.data;
        });
      }
    },

    enter() {
      this.comPanys();
    },
    openDialog(val) {
      if (val == "") {
        this.params = null;
      } else {
        this.params = val;
        // this.params.uidList = this.params.uidList.map((e) => {
        //   return { uid: e.uid, realName: e.realName };
        // });
        this.$forceUpdate();
      }
    },

    compamyMh(item) {
      //选择的公司
      console.log(item);

      // this.params.companyOriginId = item.id;
      this.params.principalName = item.value;
      this.activeClass = "none";
    },

    comPanys() {
      // console.log(this.params.companyName);
      if (!this.params.principalName) {
        return false;
      }
      searchCompany(this.params.principalName).then((res) => {
        //查找公司
        let years = [];
        if (res.success) {
          res.data.forEach((item) => {
            years.push({
              value: item.name,
              id: item.id,
            });
          });
        }
        var mkASrr = [];
        for (var i = 0; i < years.length; i++) {
          if (years[i].value.indexOf(this.params.principalName) >= 0) {
            mkASrr.push(years[i]);
          }
        }
        this.restaurantsres = mkASrr;
        this.activeClass = "block";
        if (this.restaurantsres.length == 1) {
          this.params.principalName = this.restaurantsres[0].value;
          this.activeClass = "none";
        }
      });
    },
  },

  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  components: {},
};
</script>

<style lang="scss" scoped>
.addDailog {
  // padding: 0 0 20px 0;
  display: flex;
  flex-wrap: wrap;
  .roleCodeList {
    width: 100% !important;
    height: auto;
    text-align: left !important;
  }
  .el-form-item {
    text-align: left;
    width: 48%;
    min-height: 1rem;
    .el-select {
      width: 100% !important;
    }
    .el-textarea {
      // width: 100% !important;
    }
    .el-input-number {
      width: 100% !important;
      text-align: left !important;
      .el-input__inner {
        text-align: left !important;
      }
    }
  }
}

.addDailog >>> .el-form-item {
  display: flex;
  width: 48%;
  // float: left;
}
.addDailog >>> .el-form-item__content {
  margin: 0 !important;
  width: 100% !important;
}
.addDailog >>> .el-select {
  width: 100% !important;
}
.addDailog >>> .el-autocomplete {
  width: 100% !important;
}
.addDailog >>> .el-input-number {
  width: 100% !important;
}
.addDailog >>> .el-textarea {
  width: 100% !important;
}
.mkName {
  list-style: none;
  position: absolute;
  width: 92%;
  top: 40px;
  left: 0;
  margin: 0;
  background: #fff;
  z-index: 99;
  border: 1px solid #ccc;
}
.mkName li {
  line-height: 35px;
}
.memberList {
  height: 20rem;
  overflow-y: scroll;
}
.memberList i {
  margin-left: 3px;
}
.memberList p {
  flex-shrink: 0;
}
.dialog-footer {
  /* margin-top: 100px; */
  text-align: center !important;
  .el-button {
    margin: 0 50px;
  }
}
i {
  color: #f56c6c;
  margin-right: 4px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.addContributionRatio {
  width: 20% !important;
  float: right;
  text-align: center;
  i {
    margin: 0 2px;
  }
}
</style>