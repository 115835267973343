<template>
  <el-dialog
    class="edit"
    :title="title"
    :visible="importProjectDialog"
    center
    @close="dialogClose"
    width="600px"
    :close-on-click-modal="false"
    :destroy-on-close="false"
    append-to-body
  >
    <el-form
      :model="params"
      ref="importProject"
      :rules="rules"
      class="addDailog"
      onsubmit="return false;"
      label-width="140px"
      label-position="right"
    >
      <el-form-item label="项目BD" prop="initialOwnerId">
        <el-select
          v-model="params.initialOwnerId"
          @visible-change="projectTeam($event)"
          @change="chooseInitialOwner($event)"
          filterable
          placeholder="请选择项目BD"
          clearable
          class="childWidth"
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in internalUseroptionsList"
            :key="item.uid"
            :label="item.realName"
            :value="item.uid"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="项目存在时" prop="operation">
        <el-radio-group v-model="params.operation">
          <el-radio label="1">忽略</el-radio>
          <el-radio label="2">更新</el-radio>
        </el-radio-group>
        <p class="forIndex2">
          注释：忽略(项目已存在时，跳过该项目)，更新(更新已有的项目信息)
        </p>
      </el-form-item>
    </el-form>
    <div class="importTemplateInput flexJA" @click="importTemplate">
      <el-button
        v-loading.fullscreen.lock="fullscreenLoading"
        type="primary"
        style="margin-left: 0px"
        >选择文件</el-button
      >
      <input
        type="file"
        ref="uploadExcel"
        accept=".xls, .xlsx"
        class="outputlist_upload file flexJA"
        id="importExcel"
        @change="submitImport"
        v-if="submitImportShow"
      />
    </div>
    <div class="forIndex" v-show="fileName">
      <h4>上传文件：</h4>
      {{ fileName }}
    </div>
    <div class="messageList" v-show="messageList != null">
      <h4 class="forIndex">结果：</h4>
      <span
        class="flex messageOne"
        v-for="(item, index) in messageList"
        :key="index"
      >
        {{ index + 1 }}. {{ item }}
      </span>
    </div>
    <div class="dialog-footer">
      <!-- <el-button type="primary" @click="dialogClose"></el-button> -->
      <el-button type="primary" :disabled="!importProjectButton" @click="sure"
        >导入</el-button
      >
      <el-link
        icon="el-icon-download"
        :underline="false"
        :href="templateUrl"
        type="primary"
        >没模版？下载</el-link
      >
    </div>
  </el-dialog>
</template>

<script>
import {
  importProject,
  projectTeam,
  getProjectTemplate,
} from "@/api/projectManage";
export default {
  name: "importProject",

  data() {
    return {
      title: "批量导入项目&项目模版下载",
      submitImportShow: false,
      formData: new FormData(),
      params: {
        initialOwnerId: null,
        initialOwnerName: null,
        operation: "1",
      },
      internalUseroptionsList: null,
      fileName: null,
      messageList: null,
      fullscreenLoading: false,
      importProjectButton: false,
      rules: {
        initialOwnerId: [
          { required: true, message: "请选择BD", trigger: "change" },
        ],
        operation: [
          { required: true, message: "请选择操作", trigger: "change" },
        ],
      },
      templateUrl: null,
    };
  },
  props: {
    importProjectDialog: Boolean,
    type: String,
  },
  components: {},
  computed: {},
  watch: {
    params: {
      handler(newVal, old) {
        console.log("obj.a changed1");
        if (newVal.initialOwnerId && newVal.operation) {
          this.submitImportShow = true;
          this.messageList = null;
          this.fileName = null;
        }
        if (newVal.initialOwnerId) {
          this.projectTeam(true);
          this.messageList = null;
          this.fileName = null;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  mounted() {
    this.params.initialOwnerId = window.localStorage.getItem("userId");
    this.getProjectTemplate();
  },
  methods: {
    getProjectTemplate() {
      getProjectTemplate().then((response) => {
        if (response.success) {
          this.templateUrl = response.data.template_url;
        }
      });
    },
    importTemplate() {
      console.log("23232");
      this.$refs["importProject"].validate((valid) => {
        if (valid) {
          this.formData.append("initialOwnerId", this.params.initialOwnerId);
          this.formData.append(
            "initialOwnerName",
            this.params.initialOwnerName
          );
          this.submitImportShow = true;
        }
      });
    },
    submitImport(e) {
      console.log(e);
      this.messageList = null;
      this.fileName = null;
      if (e.target.files.length > 0) {
        //声明一个FormDate对象
        // let formData = new FormData();
        this.formData.append("file", e.target.files[0]);
        this.formData.append("type", this.type);
        this.formData.append("operation", this.params.operation);
        console.log(e.target);
        let file = e.target.files[0];
        this.fileName = e.target.files[0].name;
        console.log(this.formData);
        this.importProjectButton = true;
      } else {
        this.fullscreenLoading = false;
        this.importProjectButton = false;
      }
    },
    sure() {
      this.$refs["importProject"].validate((valid) => {
        if (valid) {
          this.fullscreenLoading = true;
          importProject(this.formData)
            .then((response) => {
              if (response.success) {
                console.log(response);
                this.$message({ type: "success", message: "导入成功!" });
                this.messageList = response.data.messageList;
                this.fullscreenLoading = false;
                this.submitImportShow = false;
                this.importProjectButton = false;
                setTimeout(() => {
                  this.submitImportShow = true;
                  this.formData = new FormData();
                }, 100);
                console.log(this.formData);
              } else {
                this.fullscreenLoading = false;
                this.submitImportShow = false;
                this.importProjectButton = false;
                setTimeout(() => {
                  this.submitImportShow = true;
                  this.formData = new FormData();
                }, 100);
              }
            })
            .catch((err) => {
              //   console.warn(err);
              this.fullscreenLoading = false;
              this.submitImportShow = false;
              this.importProjectButton = false;
              setTimeout(() => {
                this.submitImportShow = true;
                this.formData = new FormData();
              }, 100);
            });
        }
      });
    },
    projectTeam(cb) {
      if (cb) {
        projectTeam({}).then((response) => {
          if (response.success) {
            this.internalUseroptionsList = response.data;
            this.chooseInitialOwner(this.params.initialOwnerId);
          }
        });
      }
    },
    chooseInitialOwner(id) {
      var that = this;
      console.log(id);
      that.internalUseroptionsList.forEach(function (item, index) {
        if (id == item.uid) {
          that.params.initialOwnerName = item.username;
        }
      });
    },
    dialogClose() {
      this.$emit("listenImportProjectClose", { type: "cancel", isClose: true });
    },
    getCustomList() {
      //模糊查询列表
      queryAccountList({
        realName: this.nameOrPhone,
        pageNum: 1,
        pageSize: 10,
      }).then((res) => {
        if (res.success) {
          this.customList = res.data;
        }
      });
    },
    //双击用户列表将订单的用户信息修改
    checkCustom(row, column, event) {
      transferProject({
        id: this.transferObj.id,
        belonger: row.uid,
      }).then((res) => {
        if (res.success) {
          this.$message.success("转移成功");
          this.nameOrPhone = null;
          this.customList = [];
          this.$emit("transferBtn", false);
        }
      });
    },
  },

  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>

<style lang="scss" scoped>
.importTemplateInput {
  position: relative;
  //   display: none;
  //   width: 200px;
  //   height: 100px;
}
.outputlist_upload {
  width: 160px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  opacity: 0;
}
.messageList {
  margin-top: 10px;
  .messageOne {
    width: 100%;
  }
}
.dialog-footer {
  margin-top: 20px;
  .el-button {
    margin-left: 150px!important;
    cursor: pointer;
    transform: translateZ(-100px);
  }
}
</style>