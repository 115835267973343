<template>
  <div>
    <el-main>
      <el-form
        class="projectFrom"
        :model="queryParams"
        ref="queryParams"
        label-width="120px"
      >
        <el-form-item label="项目名" prop="name">
          <el-input
            width="200"
            v-model="queryParams.name"
            placeholder="请输入项目名称"
            @select="handleSelect"
            clearable
          ></el-input>
          <!--    @onkey.enter='lll' -->
        </el-form-item>
        <el-form-item label="节点" prop="nodeId">
          <el-select
            v-model="queryParams.nodeId"
            class="childWidth"
            filterable
            placeholder="请选择节点"
            @visible-change="selectProjectNode($event)"
            clearable
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in projectNodeOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="投资机构" prop="orgIdList">
          <el-select
            v-model="queryParams.orgIdList"
            @visible-change="fastSearchOrg($event)"
            filterable
            placeholder="请选择"
            clearable
            multiple
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in investmenInstitutionsOptions"
              :key="item.id"
              :label="item.organizationName"
              :value="item.id"
            >
              <span>{{ item.organizationName }}</span>
              <span class="workflowVersion">{{
                item.category == 1
                  ? "财务投资"
                  : item.category == 2
                  ? "产业投资"
                  : item.category == 3
                  ? "金融机构"
                  : ""
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="industryIdList">
          <span slot="label">行业</span>
          <el-select
            v-model="queryParams.industryIdList"
            @visible-change="
              selectListDict(
                $event,
                'investmentIndustryOptions',
                'Investment_Industry'
              )
            "
            filterable
            placeholder="请选择行业"
            clearable
            multiple
            @change="investmentIndustryOne($event)"
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in investmentIndustryOptions"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="subIndustryIdList">
          <span slot="label">细分赛道</span>
          <el-select
            v-model="queryParams.subIndustryIdList"
            filterable
            placeholder="请选择细分赛道"
            clearable
            :disabled="queryParams.industryIdList.length != 1"
            @change="investmentSubIndustryOne($event)"
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in investmentsubIndustryOptions"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="所在城市" prop="provinceCode">
          <div>
            <el-select
              style="width: 50% !important"
              placeholder="请选择"
              v-model="queryParams.provinceCode"
              @change="selectProvince($event)"
              clearable
              no-data-text="加载中···"
            >
              <el-option
                v-for="item in provinceList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
            <el-select
              style="width: 50% !important"
              placeholder="请选择"
              v-model="queryParams.cityCode"
              @change="selectCityOne($event)"
              clearable
              no-data-text="加载中···"
            >
              <el-option
                v-for="city in cityList"
                :label="city.name"
                :key="city.code"
                :value="city.code"
              >
                {{ city.name }}</el-option
              >
            </el-select>
          </div>
        </el-form-item>

        <el-form-item label="BD" prop="initialOwnerId">
          <el-select
            v-model="queryParams.initialOwnerId"
            @visible-change="findProjectBdList"
            clearable
            filterable
            no-data-text="加载中···"
          >
            <el-option
              v-for="(item, index) in projectBdOptions"
              :key="index"
              :label="item.realName"
              :value="item.uid"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="revenueScaleList">
          <span slot="label"> 营收规模</span>

          <el-select
            v-model="queryParams.revenueScaleList"
            @visible-change="
              selectListDict($event, 'revenueScaleOptions', 'revenue_scale')
            "
            filterable
            placeholder="请选择"
            clearable
            multiple
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in revenueScaleOptions"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="isFavorite" style="width: 20% !important">
          <el-checkbox
            v-model="queryParams.isFavorite"
            :true-label="1"
            :false-label="null"
            @change="isFavoriteChange"
            >仅我关注的项目</el-checkbox
          >
        </el-form-item>
        <div style="margin-left: 80px; float: right">
          <el-button @click="resetConditions">重置</el-button>
          <el-button
            type="primary"
            v-permission="['admin:project:query_list:category_1']"
            @click="queryProjectList(1)"
            >查询</el-button
          >
          <el-button type="primary" @click="importProject">导入项目</el-button>
          <el-button
            type="primary"
            v-permission="['admin:project:add:category_1']"
            @click="openDialog('')"
            >新增</el-button
          >
        </div>
      </el-form>

      <!-- 表格 -->
      <el-table border :data="tableData">
        <!-- <el-table-column type="index" label="#" width="40"></el-table-column> -->
        <!-- <el-table-column label="项目编号" prop="id"></el-table-column> -->
        <el-table-column
          label="项目名称"
          align="center"
          prop="name"
        ></el-table-column>
        <el-table-column
          label="公司名称"
          align="center"
          prop="principalName"
        ></el-table-column>
        <el-table-column
          label="一句话描述"
          align="center"
          prop="brief"
        ></el-table-column>
        <el-table-column label="项目简介" align="center" prop="intro">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              title="项目简介"
              trigger="hover"
              :content="scope.row.intro"
            >
              <span slot="reference" class="beyondDisplay1">{{
                scope.row.intro
              }}</span>
            </el-popover>
          </template>
        </el-table-column>
        <!-- <el-table-column label="项目状态" prop="createDate">
          <template slot-scope="scope">
            <span>{{ scope.row.businessStatus | businessStatus }}</span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="项目批准（立项）时间" prop="approvalTime">
          <template slot-scope="scope">
            <span>{{ scope.row.approvalTime | formatDateFilter }}</span>
          </template>
        </el-table-column> -->
        <el-table-column label="操作" fixed="right" width="120">
          <template slot-scope="scope">
            <el-button
              v-permission="['admin:project:find_detail:category_1']"
              type="primary"
              size="mini"
              @click="detailDialog(scope.row)"
              >详情</el-button
            >

            <el-popover
              trigger="hover"
              placement="top"
              width="365"
              :ref="scope.$index"
            >
              <el-button
                v-permission="['admin:project:edit:category_1']"
                type="primary"
                size="mini"
                @click="openDialog(scope.row)"
                >编辑</el-button
              >
              <el-button
                v-permission="['admin:project:delete:category_1']"
                type="primary"
                size="mini"
                @click="deleteProject(scope.row)"
                >删除</el-button
              >
              <el-button
                type="primary"
                size="mini"
                @click="coypProjectCode(scope.row)"
                class="tag-read"
                >复制项目码</el-button
              >
              <el-button
                icon="el-icon-view"
                slot="reference"
                style="margin: 0 0 0 10px; font-size: 18px"
                size="small"
                type="text"
              ></el-button>
              <el-button
                type="primary"
                size="mini"
                @click="followProject(scope.row)"
                >{{
                  scope.row.isFavorite == 1 ? "取消关注" : "关注"
                }}</el-button
              >
              <!-- <el-button
              v-permission="['admin:project:claim:category_1']"
              type="primary"
              size="mini"
              @click="claimProject(scope.row)"
              >认领</el-button
            > -->
            </el-popover>
          </template>
        </el-table-column>
      </el-table>

      <div class="page-info-class">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.pageInfo.pageNum"
          :page-sizes="[10, 20]"
          :page-size="this.pageInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="this.pageInfo.total"
        ></el-pagination>
      </div>
      <!-- 转移 -->
      <el-dialog title="转移" :visible.sync="dialogVisible" width="40%">
        <transfer
          v-if="dialogVisible"
          :transferObj="transferObj"
          :dialogVisible="dialogVisible"
          v-on:transferBtn="transferBtn"
        ></transfer>
      </el-dialog>
      <!-- 添加|修改项目 -->
      <addProjects
        ref="addProjects"
        v-if="addProjectDialog"
        :type="addProjectType"
        :addProjectDialog="addProjectDialog"
        v-on:listenAddProjectClose="listenAddProjectClose"
        :projectInfo="projectInfo"
        :category="queryParams.category"
      ></addProjects>
      <!-- <el-dialog
          :title="this.dialogTitle"
          :close-on-click-modal="false"
          :visible.sync="dialogStatus"
          width="70%"
          style="padding: 0 0 100px 0px"
          v-if="dialogStatus"
        >

          <div slot="footer" class="dialog-footer">
            <el-button @click="resetForm()">取 消</el-button>

            <el-button type="primary" @click="addProject()">确 定</el-button>
          </div>
        </el-dialog> -->
      <el-dialog
        title=""
        :visible.sync="dialogVisibleEdit"
        v-if="dialogVisibleEdit"
        width="60%"
        class="editDialogs"
        :close-on-click-modal="false"
        :destroy-on-close="false"
        :lock-scroll="false"
      >
        <detailsDialog
          :msPd="msPd"
          :proId="projectId"
          :projectInfo="currRow"
          :queryParams="queryParams"
        ></detailsDialog>
        <span slot="footer" class="dialog-footer">
          <!-- <el-button @click="dialogVisibleEdit = false">取 消</el-button> -->
          <el-button type="primary" @click="dialogVisibleEdit = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <importProject
        v-if="importProjectDialog"
        :importProjectDialog="importProjectDialog"
        v-on:listenImportProjectClose="listenImportProjectClose"
        :type="importProjectType"
      ></importProject>
    </el-main>
  </div>
</template>

<script>
import {
  queryProjectList,
  deleteProjectById,
  claimProjectById,
  findProjectDetail,
  selectProjecStatus,
  coypProjectCode,
  findProjectBdList,
  followProject,
  unFollowProject,
} from "@/api/projectManage";
import { selectProjectNode } from "@/api/processNode";

import { formatDate } from "@/common/date";
import transfer from "../components/project/transfer";
import addProjects from "../components/project/addProject";
import detailsDialog from "../components/project/detailsDialog";
import importProject from "../components/project/importProject";
import { fastSearchOrg } from "@/api/institutionManage";
import { PROVINCE_LIST } from "@/common/province_code";
import { CITY_MAP } from "@/common/city_code";
export default {
  name: "project",
  data() {
    return {
      tableData: [],
      detailId: null,
      pageInfo: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
        pageCount: 1,
      },
      queryParams: {
        projectName: null,
        createUser: null,
        pageSize: 10,
        pageNum: 1,
        category: "1",
        type: "1",
        nodeId: null,
        workflowId: null,
        industryIdList: [],
        subIndustryIdList: null,
        provinceCode: [],
        cityCode: null,
        orgIdList: [],
        revenueScaleList: [],
        initialOwnerId: null,
      },
      msPd: {},
      addProjectType: null,
      transferObj: {},
      dialogTitle: "添加项目",
      dialogStatus: false,
      dialogVisible: false,
      dialogVisibleEdit: false,

      porjectStatusListOptions: null,
      addProjectDialog: false,
      projectInfo: null,
      importProjectDialog: false,
      importProjectType: "1",
      investmentIndustryOptions: [],
      investmentsubIndustryOptions: [],
      investmenInstitutionsOptions: [],
      provinceList: PROVINCE_LIST,
      provinceCityMap: CITY_MAP,
      cityList: [],
      revenueScaleOptions: [],
      projectNodeOptions: [],
      projectBdOptions: [],
      currRow: {},
      projectId: null,
    };
  },
  components: {
    transfer,
    addProjects,
    detailsDialog,
    importProject,
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    businessStatus(value) {
      switch (value) {
        case 10:
          return "待签约";
          break;
        case 20:
          return "已签约";
          break;
        case 30:
          return "BP梳理中";
          break;
        case 40:
          return "推进中";
          break;
        case 50:
          return "尽调中";
          break;
        case 60:
          return "TS";
          break;
        case 70:
          return "SPA";
          break;
      }
    },
  },
  created() {},
  mounted() {
    this.queryProjectList(1, this.pageInfo.pageSize);
  },
  //keep-alive的生命周期 ——进入组件
  activated() {
    console.log("activated2====");
    let nodeId = this.$route.query.nodeId;
    if (nodeId) {
      this.queryParams.nodeId = nodeId;
      this.queryParams.workflowId = this.$route.query.workflowId;
    }
  },
  //keep-alive的生命周期 ——离开组件
  deactivated() {
    console.log("deactivated2====");
    this.queryParams.nodeId = null;
    this.queryParams.workflowId = null;
    this.queryProjectList(1, this.pageInfo.pageSize);
  },
  methods: {
    isFavoriteChange() {
      this.$forceUpdate();
    },
    //省份选中发生变化
    selectProvince(code) {
      console.log(code);
      this.queryParams.cityCode = null;
      this.cityList = this.provinceCityMap[code];
    },
    selectCityOne(code) {
      console.log(code);
    },
    fastSearchOrg(cb) {
      if (cb) {
        let info = {
          organizationName: null,
          pageSize: 999,
          pageNum: 1,
        };
        fastSearchOrg(info).then((response) => {
          if (response.success) {
            this.investmenInstitutionsOptions = response.data;
          }
        });
      }
    },
    investmentIndustryOne(val) {
      var that = this;
      console.log(val);
      if (val != "" && that.queryParams.industryIdList.length == 1) {
        that.investmentIndustryOptions.forEach(function (item, i) {
          if (val == item.name) {
            let investmentIndustryCode = item.code;
            that.getSubIndustryOptions(true, investmentIndustryCode);
          }
        });
      } else {
        this.queryParams.subIndustryIdList = null;
      }
    },
    getSubIndustryOptions(cb, code) {
      var that = this;
      this.investmentsubIndustryOptions = [];
      if (cb) {
        that.selectListDict(true, "investmentsubIndustryOptions", code);
      }
    },
    investmentSubIndustryOne(val, index) {
      // var that = this;
      // // console.log(val);
      // let i_index = index;
      // if (val) {
      // } else {
      //   that.queryParams.industryList[i_index].subIndustry = null;
      // }
    },
    async selectListDict(e, list, type) {
      if (e) {
        this[`${list}`] = await this.$store
          .dispatch("selectListDict", type)
          .then((res) => {
            return res.data;
          });
      }
    },
    //导入模版
    importProject() {
      this.importProjectType = "1";
      this.importProjectDialog = true;
    },

    doCopy(data) {
      var that = this;
      this.$copyText(data).then(
        function (e) {
          that.$message({
            type: "success",
            message: "项目码:" + e.text + " 复制成功!",
          });
          console.log(e);
        },
        function (e) {
          that.$message({ type: "success", message: "项目码复制失败!" });
          console.log(e);
        }
      );
    },
    coypProjectCode(data) {
      if (!data.code) {
        coypProjectCode(data.id).then((response) => {
          if (response.success) {
            this.queryProjectList(
              this.pageInfo.pageNum,
              this.pageInfo.pageSize
            );
            this.$forceUpdate();
            this.doCopy(response.data.code);
          } else {
            this.$message({
              type: "info",
              message: response.message,
            });
          }
        });
      } else {
        this.doCopy(data.code);
      }
    },
    listenImportProjectClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.importProjectDialog = !isClose;
      if (type) {
        this.queryProjectList(this.pageInfo.pageNum, this.pageInfo.pageSize);
      }
    },
    listenAddProjectClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.addProjectDialog = !isClose;
      if (type == "sure") {
        this.queryProjectList(this.pageInfo.pageNum, this.pageInfo.pageSize);
      }
    },
    selectProjecStatus(cb) {
      if (cb) {
        selectProjecStatus({}).then((response) => {
          if (response.success) {
            this.porjectStatusListOptions = response.data;
          }
        });
      }
    },
    selectProjectNode(cb) {
      if (cb) {
        selectProjectNode({}).then((response) => {
          if (response.success) {
            this.projectNodeOptions = response.data;
          }
        });
      }
    },
    transferProject(row) {
      this.transferObj = row;
      this.dialogVisible = true;
    },
    transferBtn(val) {
      this.queryProjectList(1, this.pageInfo.pageSize);
      this.dialogVisible = val;
      console.log(val);
    },
    querySearch(queryString, cb) {
      //项目模糊查找
      var arrCompany = [];
      this.tableData.forEach((res) => {
        arrCompany.push({
          value: res.projectName,
          id: res.id,
        });
      });
      var results = queryString
        ? arrCompany.filter(this.createFilter(queryString))
        : arrCompany;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    handleSelect(item) {
      //选择的项目
      this.queryParams.id = item.id;
      this.queryParams.projectName = item.value;
    },
    detailDialog(row) {
      this.currRow = row;
      this.projectId = row.id;
      // this.dialogVisibleEdit = true;
      this.$router.push({
        name: "projectDetails",
        query: { projectId: row.id },
        params:{...this.queryParams}
      });
    },
    assessmentClick(row) {
      //评估
      this.$store.commit("setRouterType", "openWindow");
      const { href } = this.$router.resolve({
        name: `assessment`,
        query: {
          rows: row.id,
          name: row.projectName,
        },
      });
      window.open(href);
    },
    ProcessManagement(id, projectName) {
      //流程进展
      this.$router.push({
        path: "/processManagement",
        query: { id: id, name: projectName },
      });
    },
    resetConditions() {
      this.$refs.queryParams.resetFields();
      this.queryParams.isFavorite = null;
      this.pageInfo.pageNum = 1;
      this.pageInfo.pageSize = 10;
      this.queryProjectList(this.pageInfo.pageNum, this.pageInfo.pageSize);
    },
    queryProjectList(num, pageSize) {
      //项目库列表
      let queryParams = JSON.parse(JSON.stringify(this.queryParams));
      queryParams.pageNum = num;
      queryParams.pageSize = pageSize;
      if (queryParams.subIndustryIdList) {
        let list = [];
        list.push(this.queryParams.subIndustryIdList);
        queryParams.subIndustryIdList = list;
      }
      if (queryParams.provinceCode && !queryParams.cityCode) {
        this.$message({ type: "warning", message: "请选全地区!" });
        return false;
      }
      console.log(queryParams);
      queryProjectList(queryParams).then((response) => {
        this.tableData = response.data;
        // console.log(this.tableData);
        if (response.total != null) {
          this.pageInfo.pageNum = response.pageNum;
          this.pageInfo.pageSize = response.pageSize;
          this.pageInfo.total = response.total;
          this.pageInfo.pageCount = response.pageCount;
        } else {
          this.pageInfo.total = 0;
        }
      });
    },

    handleSizeChange(val) {
      console.log(val);
      this.queryProjectList(1, val);
    },
    handleCurrentChange(val) {
      console.log(val);
      if (val > this.pageInfo.pageCount) {
        val = this.pageInfo.pageCount;
      }
      this.queryProjectList(val, this.pageInfo.pageSize);
    },
    openDialog(val) {
      console.log(val);
      if (val == "") {
        this.addProjectType = "add";
        this.dialogTitle = "添加项目";
        this.msPd = val;
      } else {
        // console.log(val);
        this.addProjectType = "edit";
        this.dialogTitle = "编辑项目";
        this.projectInfo = val;
      }
      console.log(this.projectInfo);

      this.addProjectDialog = true;
    },
    followProject(data) {
      let title = data.isFavorite == 1 ? "取消关注" : "关注";
      let info = {
        refType: "project",
        refId: data.id,
      };
      this.$confirm("确认" + title + "项目 [" + data.name + "] 吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (data.isFavorite == "0") {
            followProject(info).then((response) => {
              if (response.success) {
                this.$message({ type: "success", message: "关注成功!" });
                this.queryProjectList(
                  this.pageInfo.pageNum,
                  this.pageInfo.pageSize
                );
              } else {
                this.$message({
                  type: "info",
                  message: response.message,
                });
              }
            });
          } else if (data.isFavorite == "1") {
            unFollowProject(info).then((response) => {
              if (response.success) {
                this.$message({ type: "success", message: "取消关注成功!" });
                this.queryProjectList(
                  this.pageInfo.pageNum,
                  this.pageInfo.pageSize
                );
              } else {
                this.$message({
                  type: "info",
                  message: response.message,
                });
              }
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    deleteProject(data) {
      this.$confirm("确认删除项目 [" + data.name + "] 吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteProjectById(data.id).then((response) => {
            if (response.success) {
              this.$message({ type: "success", message: "删除成功!" });
              this.dialogStatus = false;
              this.queryProjectList(
                this.pageInfo.pageNum,
                this.pageInfo.pageSize
              );
            } else {
              this.$message({
                type: "info",
                message: response.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    claimProject(data) {
      this.$confirm("确认认领项目 [" + data.name + "] 吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          claimProjectById(data.id).then((response) => {
            if (response.success) {
              this.$message({ type: "success", message: "认领成功!" });
              this.dialogStatus = false;
              this.queryProjectList(
                this.pageInfo.pageNum,
                this.pageInfo.pageSize
              );
            } else {
              this.$message({
                type: "info",
                message: response.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },

    //查询项目BD列表
    findProjectBdList() {
      let params = {
        idList: [],
        type: 1,
      };
      findProjectBdList(params).then((res) => {
        if (res.success) {
          this.projectBdOptions = res.data;
        }
      });
    },
  },

  watch: {},
};
</script>

<style lang="scss" scoped>
.projectFrom {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.projectFrom .el-form-item {
  width: 30%;
  flex-shrink: 0;
  // display: flex;
}
.el-select {
  width: 100% !important;
}
.projectFrom >>> .el-dialog__body {
  text-align: left;
}
// .projectFrom >>> .el-form-item {
//   display: flex;
//   width: 48%;
//   // float: left;
// }
.projectFrom >>> .el-form-item__content {
  // margin: 0 !important;
  // width: 100% !important;
}
.projectFrom .el-form-item >>> .el-select {
  width: 100% !important;
}
.projectFrom ul {
  list-style: none;
  text-align: left;
  line-height: 30px;
}
.dialog-footer {
  /* margin-top: 100px; */
  text-align: center !important;
  .el-button {
    margin: 0 50px;
  }
}
.el-dialog__footer {
  /* margin-top: 100px; */
  text-align: center !important;
}
body .el-table th.gutter {
  display: table-cell !important;
}

body .el-table colgroup.gutter {
  display: table-cell !important;
}

.el-table {
  width: 100% !important;
}

.el-table__body {
  // 使表格兼容safari，不错位
  width: 100%;
  table-layout: fixed !important;
}
</style>
